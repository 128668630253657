import React from "react";

const Footer = () => {
  return (
    <footer>
      <h1>Footer</h1>
    </footer>
  );
};

export default Footer;
